const classNames = (...classes: Array<string | boolean>): string =>
  classes.filter(Boolean).join(" ");

/**
 * @description Format date to `MM/DD/YYYY`
 */
const formatDate = (unformattedDate: Date | string | undefined): string => {
  if (typeof unformattedDate === "undefined") return "";

  const date =
    typeof unformattedDate === "string"
      ? new Date(unformattedDate)
      : unformattedDate;
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

/**
 * @description Format time to `HH:mm:ss`
 */
const formatTime = (unformattedTime: Date | string): string => {
  const date =
    typeof unformattedTime === "string"
      ? new Date(unformattedTime)
      : unformattedTime;
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return `${hours}:${minutes}:${seconds}`;
};

const formatPhoneNumber = (phoneNumberString: string): string | null => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

const capitalizeAll = (str: string | null | undefined): string => {
  if (typeof str === "string") {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return "";
};

/**
 * @description Remove undefined and null from an object.
 *
 * It will return a string with next structure: '?key=value&key=value'
 */
const convertToSearchParams = (
  obj: Record<
    string,
    string | number | boolean | Array<string> | null | undefined
  >,
): string => {
  const searchParams = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    const keyValue = obj[key];

    if (typeof keyValue === "number" || typeof keyValue === "boolean") {
      return searchParams.append(key, keyValue.toString());
    }

    if (typeof keyValue === "string") {
      return searchParams.append(key, keyValue);
    }

    if (keyValue instanceof Array) {
      return keyValue.forEach((value) => searchParams.append(key, value));
    }
  });

  return `?${searchParams.toString()}`;
};

const decodeRoleType = {
  admin: "Admin",
  manager: "Manager",
  regular: "Regular",
  superAdmin: "Super Admin",
  viewer: "Viewer",
};

/**
 * @description Turn every falsy value into null.
 *
 * It will return null or the value in string format
 */
const checkFalsyValue = (str: string | null | undefined | number) => {
  switch (str) {
    case undefined:
      return null;
    case null:
      return null;
    case "":
      return null;
    case " ":
      return null;
    case 0:
      return null;
    case "0":
      return null;
    case "None":
      return null;

    default:
      return String(str);
  }
};

const cleanObject = (obj: Record<string, unknown>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      if (Array.isArray(value)) {
        // Remove empty arrays
        return value.length > 0;
      }

      return value !== null && value !== undefined && value !== "";
    }),
  );
};

export {
  cleanObject,
  formatDate,
  classNames,
  formatPhoneNumber,
  capitalizeAll,
  formatTime,
  convertToSearchParams,
  decodeRoleType,
  checkFalsyValue,
};
